import { Capacitor } from "@capacitor/core";
import { useResponsive } from "ahooks";
import { useEffect } from "react";

export const useIsWideScreen = () => {
  const resp = useResponsive() || {};
  const wideScreen = resp.lg || resp.xl;

  return wideScreen;
};

const appHeight = () =>
  document.documentElement.style.setProperty(
    "--app-height",
    `${window.innerHeight}px`
  );

const unit = 60; // px
export const svh100 = "var(--app-height)";

export const useCollapseToBottom = (
  collapseToBottom?: boolean,
  level: number = 1,
  baseLevel: number = 0
) => {
  const resp = useIsWideScreen(); //useResponsive() || defaultResponsive;
  const isNarrow = collapseToBottom && !resp; // && !resp.md;
  const doCollapse = isNarrow || baseLevel;
  const _level = doCollapse && isNarrow ? level : 0;

  useEffect(() => {
    window.addEventListener("resize", appHeight);
    appHeight();
    return () => window.removeEventListener("resize", appHeight);
  }, []);

  const collapsedMenuHeight = `${unit * (_level + baseLevel)}px`;
  const contentHeight = doCollapse
    ? `calc(${svh100} - ${collapsedMenuHeight})`
    : svh100;
  const menuHeight = doCollapse ? collapsedMenuHeight : svh100;

  return [doCollapse /* && !resp.md */, menuHeight, contentHeight, resp] as [
    boolean,
    string,
    string,
    typeof resp,
  ];
};
